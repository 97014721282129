define(['app', 'productSkuWidget'], function(app) {
  
  var _productHeightFixer = {
    /**
     * Module Constructor
     * @private
     */
    _init: function() {
      var rows = document.querySelectorAll('.row');

      app.subscribe('products/fixHeight', function() {
        rows = document.querySelectorAll('.row');

        if (rows.length) {
          _productHeightFixer._checkRows(rows);
        }
      });

      if (rows.length) {
        this._checkRows(rows);
      }
    },

    /**
     * Get each product row
     * @param {NodeList} rows
     * @private
     */
    _checkRows: function(rows) {
      for (var i = 0, rowsLength = rows.length; i < rowsLength; i++) {
        this._checkRow(rows[i]);
      }
    },

    /**
     * Determine the height of the product row
     * @param {object} row
     * @private
     */
    _checkRow: function(row) {
      var items = row.querySelectorAll('.item');
      if (items.length) {
        var maxHeight = 0;
        for (var i = 0, itemLength = items.length; i < itemLength; i++) {
          var item = items[i];
          var productInformation = item.querySelectorAll('.line');
          if (productInformation.length > 1) {
            var productInformationHeight = productInformation[1].offsetHeight;
            if (productInformationHeight > maxHeight) {
              maxHeight = productInformationHeight;
            }
          }
        }
        this._setRowHeight(maxHeight, items);
      }
    },

    /**
     * Set the height of each product
     * @param {number} maxHeight
     * @param {NodeList} items
     * @private
     */
    _setRowHeight: function(maxHeight, items) {
      for (var i = 0, itemLength = items.length; i < itemLength; i++) {
        var item = items[i];
        var productInformation = item.querySelectorAll('.line');
        if (productInformation.length > 1) {
          var title = productInformation[1].querySelectorAll('.product-name');
          if (title.length) {
            var currentMargin = Number(this._getStyleNumber(title[0], 'margin-bottom', 'marginBottom').split('px')[0]);
            var margin = (maxHeight + currentMargin) - productInformation[1].offsetHeight;
            title[0].setAttribute('style', 'margin-bottom:' + String(margin) + 'px');
          }
        }
      }
    },

    /**
     * Get the value of a applied CSS rule
     * @param {object} element
     * @param {string} property
     * @param {string} objectProperty
     * @returns {object}
     * @private
     */
    _getStyleNumber: function(element, property, objectProperty) {
      if (window.getComputedStyle) {
        return getComputedStyle(element, null).getPropertyValue(property);
      }
      return element.currentStyle[objectProperty];
    }
  };

  // Execute module constructor
  _productHeightFixer._init();

  // Unit testing access
  return _productHeightFixer;
});
